import styled from 'styled-components';
import { animated } from 'react-spring';
import { device } from '../../../../styles/media';

export const BgWrapper = styled(animated.div)`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-image: url('/png/commons/navigationBG.png');
  background-position: bottom;
  background-repeat: no-repeat;
  position: fixed;
  padding: 10px 40px;
  z-index: 30;

  @media ${device.web}, ${device.wideTab} {
    display: none;
  }

  @media ${device.tab} {
    padding: 14px 60px;
  }

  @media ${device.mobile} {
    padding: 14px 20px;
  }
`;
export const LogoImg = styled.img`
  width: 42px;
`;

export const Title = styled.div`
  font-size: 1.75rem;
  font-weight: 600;

  @media ${device.mobile} {
    font-size: 1.5rem;
  }
`;

export const IconBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 90px;
`;

import styled from 'styled-components';
import { device } from '../../../../styles/media';

export const Wrapper = styled.div`
  display: flex;
  justify-content: right;
  width: 80%;
  max-width: 1400px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);

  @media ${device.wideTab} {
    margin-top: 60px;
    width: 75%;
  }
  @media ${device.tab} {
    margin-top: 70px;
    width: 90%;
  }

  @media ${device.mobile} {
    margin-top: 0;
    width: 90%;
    justify-content: left;
  }
`;

export const Text = styled.div`
  font-size: 12px;
  margin-top: 20px;
  opacity: 50%;
`;

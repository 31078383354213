import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-scroll';
import * as S from './styles';
import Navigation from '../navigation';
import { goToTop, MenuItems } from '../../../../utils/menuItems';

const Header = () => {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState<string>(location.pathname);
  const [blurHeader, setBlurHeader] = useState<boolean>(false);
  const [onNavigation, setOnNavigation] = useState<boolean>(false);

  const MenuStyle = (page: string) => ({
    fontSize: `1rem`,
    fontWeight: `600`,
    textDecoration: `none`,
    color: `#ffffff`,
    borderBottom: page === activeMenu ? `4px solid #21B9C2` : `none`,
    opacity: page === activeMenu ? `100%` : `80%`,
    cursor: `pointer`,
  });

  const onClickHamburger = () => {
    setOnNavigation(true);
  };

  const onScroll = () => {
    setBlurHeader(window.scrollY >= 150);
    let currentSection = ``;

    MenuItems.forEach((section) => {
      const element = document.getElementById(section.page);
      if (element) {
        const { top } = element.getBoundingClientRect();
        if (top <= 0) {
          currentSection = section.page;
        }
      }
    });

    setActiveMenu(currentSection);
  };

  useEffect(() => {
    window.addEventListener(`scroll`, onScroll);
    return () => {
      window.removeEventListener(`scroll`, onScroll);
    };
  }, []);

  return (
    <>
      <S.Wrapper $blurHeader={blurHeader}>
        <S.Box>
          <S.logoBox>
            <S.LogoImg
              src="/svg/commons/logo.svg"
              alt="logo"
              onClick={goToTop}
            />
          </S.logoBox>
          <S.MenuBox>
            {MenuItems.map(({ id, title, page }) => (
              <Link key={id} to={page} style={MenuStyle(page)} smooth>
                {title}
              </Link>
            ))}
          </S.MenuBox>
          <S.Hamburger
            src="/svg/commons/menu.svg"
            alt="menu"
            onClick={onClickHamburger}
          />
        </S.Box>
      </S.Wrapper>
      {onNavigation && (
        <Navigation setOnNavigation={setOnNavigation} activeMenu={activeMenu} />
      )}
    </>
  );
};

export default Header;

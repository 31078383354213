import * as S from './styles';

const HomeContainer = () => {
  return (
    <S.BackWrapper>
      <S.BgWrapper>
        <S.WebVideo autoPlay muted loop controls={false} playsInline>
          <source src="mp4/flux_web.mp4" type="video/mp4" />
        </S.WebVideo>
        <S.TabVideo autoPlay muted loop controls={false} playsInline>
          <source src="mp4/flux_tab.mp4" type="video/mp4" />
        </S.TabVideo>
        <S.MobileVideo autoPlay muted loop controls={false} playsInline>
          <source src="mp4/flux_mo.mp4" type="video/mp4" />
        </S.MobileVideo>
      </S.BgWrapper>
      <S.Wrapper>
        <S.Title type="web">
          FLUX, the simplest
          <br />
          Liquid Delegation Solution
          <br />
          for K STADIUM.
        </S.Title>
        <S.Title type="mob">
          FLUX,
          <br />
          the simplest Liquid
          <br />
          Delegation Solution
          <br />
          for K STADIUM.
        </S.Title>
        <S.ContentsBox>
          <S.Contents type="web">
            We will be a partner that allows anyone to realize their dreams
            <br />
            without limiting possibilities.
          </S.Contents>
          <S.Contents type="mob">
            We will be a partner that allows anyone
            <br />
            to realize their dreams without limiting
            <br />
            possibilities.
          </S.Contents>
        </S.ContentsBox>
        <S.ButtonBox>
          <S.ButtonLink
            href="https://k-flux.xyz/"
            target="_blank"
            rel="noreferrer"
          >
            <S.Button type="LG">FLUX</S.Button>
          </S.ButtonLink>
          <S.ButtonLink
            href="https://kstadium.io/"
            target="_blank"
            rel="noreferrer"
          >
            <S.Button type="BD">K STADIUM</S.Button>
          </S.ButtonLink>
        </S.ButtonBox>
      </S.Wrapper>
    </S.BackWrapper>
  );
};

export default HomeContainer;

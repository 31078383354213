import * as S from './styles';

const AboutUsTabContainer = () => {
  return (
    <S.Wrapper>
      <S.TabBox>
        <S.Section>
          <S.ImageBox>
            <S.Image src="/svg/aboutUs/property.svg" alt="logo" />
          </S.ImageBox>
          <div>
            <S.Contents>Core liquidity services</S.Contents>
            <S.SubContentsTab>
              FLUX, is the core liquidity
              <br />
              provider to passionate about
              <br />
              bringing your valuable
              <br />
              cryptocurrency and delegated
              <br />
              assets to achieve brighter
              <br />
              future.
            </S.SubContentsTab>
          </div>
        </S.Section>
        <S.Section>
          <S.ImageBox>
            <S.Image src="/svg/aboutUs/security.svg" alt="logo" />
          </S.ImageBox>
          <div>
            <S.Contents>Liquidity providers</S.Contents>
            <S.SubContentsTab>
              FLUX, is to create and operate
              <br />
              the leading liquidity protocol on
              <br />
              the various ways that we offer
              <br />
              access to new utilizing assets to
              <br />
              our delegated users based on
              <br />
              the Ground Chain (GND).
            </S.SubContentsTab>
          </div>
        </S.Section>
        <S.Section>
          <S.ImageBox>
            <S.Image src="/svg/aboutUs/staking.svg" alt="logo" />
          </S.ImageBox>
          <div>
            <S.Contents>Steady Liquidity protocol</S.Contents>
            <S.SubContentsTab>
              FLUX, is a based liquidity
              <br />
              protocol that has been created
              <br />
              by fundamental of stETH from
              <br />
              the Ethereum 2.0 liquidity
              <br />
              staking solution to ensure a
              <br />
              steady supply of liquidity.
            </S.SubContentsTab>
          </div>
        </S.Section>
      </S.TabBox>
    </S.Wrapper>
  );
};

export default AboutUsTabContainer;

import React, { useState } from 'react';
import * as S from './styles';

const ServiceContainer = () => {
  const [activeButton, setActiveButton] = useState<string>(`Delegation`);

  const buttons = [
    { id: 1, title: `Delegation` },
    { id: 2, title: `Undelegation` },
  ];

  const onClickButton = (title: string) => () => {
    setActiveButton(title);
  };

  return (
    <S.BgWrapper>
      <S.Wrapper>
        <S.SubTitle>What we do</S.SubTitle>
        <S.Title>Service</S.Title>
        <S.ButtonBox>
          {buttons.map(({ id, title }) => (
            <S.StateButton
              key={id}
              title={title}
              onClick={onClickButton(title)}
              $activeButton={activeButton}
            >
              {title}
            </S.StateButton>
          ))}
        </S.ButtonBox>
        <S.ChartBox>
          <S.ChartImg
            src={
              activeButton === `Delegation`
                ? `/png/service/delegate.png`
                : `/png/service/undelegate.png`
            }
            alt="delegate"
            type="web"
          />
        </S.ChartBox>
        <S.ChartImg
          src={
            activeButton === `Delegation`
              ? `/png/service/delegate_mob.png`
              : `/png/service/undelegate_mob.png`
          }
          alt="delegate"
          type="mob"
        />
        <S.TextBox>
          <S.ContentTitle>
            {activeButton === `Delegation` ? `Delegation` : `Undelegation`}
          </S.ContentTitle>
          {activeButton === `Delegation` ? (
            <>
              <S.Description type="web">
                Delegation of SOP token is requested through user action.
                <br />
                If it is the first use case which EOA requesting delegation has
                no transaction history with SO to be delegated, FLUX
                <br /> Controller requests Factor to create a new Delegator that
                matches user EOA and SO.
                <br /> As a result of the request, EOA’s SOP token is
                transferred to the newly created (or previously created)
                Delegator.
                <br /> Delegator who receives SOP token, executes the delegation
                to the selected SO chosen by users.
                <br /> FLUX Controller issues(mint) inKSTA corresponding to the
                delegated SOP upon delegation request and delivers it to
                <br /> users.
              </S.Description>
              <S.Description type="mob">
                Delegation of SOP token is requested through user action. If it
                is the first use case which EOA requesting delegation has no
                transaction history with SO to be delegated, FLUX Controller
                requests Factor to create a new Delegator that matches user EOA
                and SO. As a result of the request, EOA’s SOP token is
                transferred to the newly created (or previously created)
                Delegator. Delegator who receives SOP token, executes the
                delegation to the selected SO chosen by users. FLUX Controller
                issues(mint) inKSTA corresponding to the delegated SOP upon
                delegation request and delivers it to users.
              </S.Description>
            </>
          ) : (
            <>
              <S.Description type="web">
                When users holding inKSTA request undelegation,
                <br />
                FLUX Controller searches for the user in delegator list and
                forwards the Tx.
                <br />
                Delegator executes undelegation in the selected SO based on the
                forwarded Tx
                <br />
                and calculates the returned SOP and reward amount.
                <br /> FLUX Controller delivers rewards to users based on the
                quantity and ratio of undelegation requests,
                <br />
                using UnDelegator logic. During this process, FLUX Controller
                burns an equivalent
                <br />
                amount of inKSTA as the amount requested for undelegation, and
                returns the resulting SOP to users.
              </S.Description>
              <S.Description type="mob">
                When users holding inKSTA request undelegation, FLUX Controller
                searches for the user in delegator list and forwards the Tx.
                Delegator executes undelegation in the selected SO based on the
                forwarded Tx and calculates the returned SOP and reward amount.
                FLUX Controller delivers rewards to users based on the quantity
                and ratio of undelegation requests, using UnDelegator logic.
                During this process, FLUX Controller burns an equivalent amount
                of inKSTA as the amount requested for undelegation, and returns
                the resulting SOP to users.
              </S.Description>
            </>
          )}
        </S.TextBox>
      </S.Wrapper>
    </S.BgWrapper>
  );
};

export default ServiceContainer;

import styled from 'styled-components';
import { device } from '../../styles/media';

export const BgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0;
  width: 100vw;
  height: 1688px;
  background-color: #001c2a;

  @media ${device.wideTab}, ${device.tab} {
    height: 1166px;
  }

  @media ${device.mobile} {
    height: 1056px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 9%;

  @media ${device.wideTab}, ${device.tab} {
    padding: 0 13.5%;
  }

  @media ${device.mobile} {
    padding: 0;
  }
`;

export const SubTitle = styled.div`
  font-weight: 300;
  font-size: 1.5rem;
  color: #21b9c2;

  @media ${device.wideTab}, ${device.tab} {
    font-size: 0.875rem;
  }

  @media ${device.mobile} {
    font-weight: 400;
    font-size: 1rem;
    color: #ffffff;
    opacity: 50%;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 3rem;

  @media ${device.wideTab}, ${device.tab} {
    font-size: 1.875rem;
  }

  @media ${device.mobile} {
    font-size: 1.75rem;
  }
`;

export const ButtonBox = styled.div`
  background-color: rgba(255, 255, 255, 0.05);
  width: 552px;
  height: 94px;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-top: 80px;
  cursor: pointer;

  @media ${device.wideTab} {
    width: 375px;
    height: 84px;
    gap: 16px;
    margin-top: 40px;
    padding: 16px;
  }

  @media ${device.tab} {
    width: 536px;
    height: 84px;
    gap: 24px;
    margin-top: 40px;
    padding: 16px;
  }

  @media ${device.mobile} {
    width: 320px;
    height: 52px;
    margin-top: 24px;
    padding: 8px;
  }
`;

export const StateButton = styled.div<{ $activeButton: string; title: string }>`
  width: 240px;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 80px;
  font-size: 1.25rem;
  font-weight: 600;
  background-color: ${({ $activeButton, title }) =>
    $activeButton === title ? `#21B9C2` : `#2B3C47`};
  color: ${({ $activeButton, title }) =>
    $activeButton === title ? `#ffffff` : `#3D4E55`};

  @media ${device.wideTab}, ${device.tab} {
    width: 174px;
    height: 52px;
    font-size: 1rem;
  }

  @media ${device.tab} {
    width: 238px;
    height: 52px;
    font-size: 1rem;
  }

  @media ${device.mobile} {
    width: 148px;
    height: 36px;
    font-size: 1rem;
  }
`;

export const ChartBox = styled.div`
  width: 100%;
  height: 740px;
  background-color: rgba(10, 56, 83, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border-radius: 20px;

  @media ${device.wideTab}, ${device.tab} {
    width: 720px;
    height: 402px;
  }

  @media ${device.mobile} {
    display: none;
  }
`;

export const ChartImg = styled.img<{ type?: `web` | `mob` }>`
  width: 914px;
  display: ${({ type }) => (type === `web` ? `block` : `none`)};

  @media (min-width: 1024px) and (max-width: 1100px) {
    width: 850px;
  }

  @media ${device.wideTab}, ${device.tab} {
    width: 518px;
  }

  @media ${device.mobile} {
    width: 90%;
    margin: 20px 0;
    display: ${({ type }) => (type === `mob` ? `block` : `none`)};
  }
`;

export const TextBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: rgba(3, 32, 47, 0.5);
  margin-top: 20px;
  padding: 48px 64px;
  border-radius: 20px;

  @media ${device.wideTab}, ${device.tab} {
    width: 720px;
    height: 348px;
  }

  @media ${device.mobile} {
    height: 490px;
    width: 90%;
    flex-direction: column;
    justify-content: flex-start;
    padding: 25px 20px;
    background-color: rgba(10, 56, 83, 0.3);
  }
`;

export const ContentTitle = styled.div`
  font-size: 2rem;
  font-weight: 500;
  margin-right: 60px;

  @media ${device.wideTab}, ${device.tab} {
    font-size: 1.125rem;
  }

  @media ${device.mobile} {
    font-size: 1.25rem;
    margin-right: 0;
  }
`;

export const Description = styled.div<{ type?: `web` | `mob` }>`
  font-size: 1.125rem;
  display: ${({ type }) => (type === `web` ? `block` : `none`)};

  @media ${device.wideTab}, ${device.tab} {
    font-size: 0.875rem;
  }

  @media ${device.mobile} {
    display: ${({ type }) => (type === `mob` ? `block` : `none`)};
    font-size: 0.875rem;
    padding-top: 20px;
  }
`;

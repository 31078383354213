import * as S from './styles';
import { goToTop } from '../../../../utils/menuItems';

const TopButton = () => {
  return (
    <S.ButtonBox onClick={goToTop}>
      <img src="/svg/commons/top.svg" alt="topButton" />
    </S.ButtonBox>
  );
};

export default TopButton;

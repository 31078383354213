import styled from 'styled-components';
import { device } from '../../../../styles/media';

export const ButtonBox = styled.div`
  position: fixed;
  right: 40px;
  bottom: 40px;
  cursor: pointer;

  @media ${device.mobile} {
    right: 20px;
    bottom: 20px;
  }
`;

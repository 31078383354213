import { animated, useSpring } from 'react-spring';
import React, { useState } from 'react';
import * as S from './styles';
import AboutUsCarousel from './carousel';
import AboutUsTabContainer from './tab';

const AboutContainer = () => {
  const [coords, setCoords] = useState({ x: 0, y: 0 });

  const calc = (x: number, y: number) => {
    const xTrans = (x - window.innerWidth / 2) / 8;
    const yTrans = (y - window.innerHeight / 2) / 5;
    return [-xTrans, -yTrans];
  };

  const springProps = useSpring({
    xy: calc(coords.x, coords.y),
    config: { tension: 170, friction: 26 },
  });

  const onMoveMouse = (event: React.MouseEvent<HTMLDivElement>) => {
    setCoords({ x: event.clientX, y: event.clientY });
  };

  const onLeaveMouse = () => {
    setCoords({ x: window.innerWidth / 2, y: window.innerHeight / 2 });
  };

  return (
    <S.BgWrapper>
      <S.Wrapper>
        <S.SubTitle>FLUX is</S.SubTitle>
        <S.Title>About us</S.Title>
        <S.Box>
          <S.Section onMouseMove={onMoveMouse} onMouseLeave={onLeaveMouse}>
            <animated.div
              style={{
                transform: springProps.xy.to(
                  (x, y) => `translate3d(${x}px, ${y}px, 0)`,
                ),
              }}
            >
              <S.ImageBox type="PR">
                <S.Image src="/svg/aboutUs/property.svg" alt="property" />
              </S.ImageBox>
            </animated.div>
            <S.TextBox type="PR">
              <S.Contents>Core liquidity services</S.Contents>
              <S.SubContents>
                FLUX, is the core liquidity provider to <br />
                passionate about bringing your valuable <br />
                cryptocurrency and delegated assets to <br />
                achieve brighter future.
              </S.SubContents>
            </S.TextBox>
          </S.Section>
          <S.Section onMouseMove={onMoveMouse} onMouseLeave={onLeaveMouse}>
            <animated.div
              style={{
                transform: springProps.xy.to(
                  (x, y) => `translate3d(${x}px, ${y}px, 0)`,
                ),
              }}
            >
              <S.ImageBox type="SE">
                <S.Image src="/svg/aboutUs/security.svg" alt="security" />
              </S.ImageBox>
            </animated.div>
            <S.TextBox type="SE">
              <S.Contents>Liquidity providers</S.Contents>
              <S.SubContents>
                FLUX, is to create and operate the leading <br />
                liquidity protocol on the various ways that <br />
                we offer access to new utilizing assets to <br />
                our delegated users based on the Ground <br />
                Chain (GND).
              </S.SubContents>
            </S.TextBox>
          </S.Section>
          <S.Section onMouseMove={onMoveMouse} onMouseLeave={onLeaveMouse}>
            <animated.div
              style={{
                transform: springProps.xy.to(
                  (x, y) => `translate3d(${x}px, ${y}px, 0)`,
                ),
              }}
            >
              <S.ImageBox type="ST">
                <S.Image src="/svg/aboutUs/staking.svg" alt="staking" />
              </S.ImageBox>
            </animated.div>
            <S.TextBox type="ST">
              <S.Contents>Steady Liquidity protocol</S.Contents>
              <S.SubContents>
                FLUX, is a based liquidity protocol that has <br />
                been created by fundamental of stETH <br />
                from the Ethereum 2.0 liquidity staking <br />
                solution to ensure a steady supply of <br />
                liquidity.
              </S.SubContents>
            </S.TextBox>
          </S.Section>
        </S.Box>
        <AboutUsTabContainer />
        <AboutUsCarousel />
      </S.Wrapper>
    </S.BgWrapper>
  );
};

export default AboutContainer;

import HomeContainer from '../../components/home';
import AboutContainer from '../../components/aboutUs';
import ServiceContainer from '../../components/service';
import RoadMap from '../roadmap';
import Business from '../business';
import TopButton from '../../components/commons/buttons/topButton';
import FluxEduButton from '../../components/commons/buttons/fluxEduButton';

const Home = () => {
  return (
    <>
      <div id="/">
        <HomeContainer />
      </div>
      <div id="/aboutus">
        <AboutContainer />
      </div>
      <div id="/service">
        <ServiceContainer />
      </div>
      <div id="/roadmap">
        <RoadMap />
      </div>
      <div id="/business">
        <Business />
      </div>
      <FluxEduButton />
      <TopButton />
    </>
  );
};
export default Home;

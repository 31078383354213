import styled from 'styled-components';
import Slider from 'react-slick';
import { device } from '../../../styles/media';

export const Wrapper = styled.div`
  @media ${device.web}, ${device.wideTab}, ${device.tab} {
    display: none;
  }
  width: 100%;
  height: 100%;
  margin-top: 24px;
`;

export const Slide = styled(Slider)`
  .slick-list {
    width: 310px;
    margin: 0 auto;
    border-radius: 8px;
  }

  .slick-slide {
    padding: 0 20px;
  }

  .slick-dots {
    .slick-active {
      width: 36px;
      height: 6px;
      button::before {
        width: 36px;
        height: 6px;
        border-radius: 10px;
        margin-top: 16px;
        color: rgba(0, 0, 0, 0);
        background-color: #21b9c2;
      }
    }
    button::before {
      width: 16px;
      height: 6px;
      border-radius: 10px;
      color: rgba(0, 0, 0, 0);
      margin-top: 16px;
      opacity: 50%;
      background-color: #ffffff;
    }
  }
`;

export const Section = styled.div`
  height: 456px;
  padding: 30px 20px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

export const ImageBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

export const Image = styled.img`
  @media ${device.wideTab}, ${device.tab} {
    width: 152px;
  }

  @media ${device.mobile} {
    width: 192px;
  }
`;

export const Contents = styled.div`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 20px;
`;

export const Description = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
`;

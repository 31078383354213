import { Link } from 'react-scroll';
import { useSpring } from 'react-spring';
import { useState } from 'react';
import * as S from './styles';
import { MenuItems } from '../../../../utils/menuItems';

export interface NavigationProps {
  activeMenu?: string;
  setOnNavigation?: (value: boolean) => void;
}

const Navigation = ({ setOnNavigation, activeMenu }: NavigationProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const MenuStyle = (page: string) => ({
    fontSize: `1.75rem`,
    fontWeight: `600`,
    textDecoration: `none`,
    marginBottom: `30px`,
    color: page === activeMenu ? `#21B9C2` : `#ffffff`,
    cursor: `pointer`,
  });

  const slideAnimation = useSpring({
    from: { transform: isOpen ? `translateX(100%)` : `translateX(0%)` },
    to: { transform: isOpen ? `translateX(0%)` : `translateX(100%)` },
    config: { duration: 400 },
  });

  const onClickCloseButton = () => {
    setIsOpen(false);
    setTimeout(() => {
      if (setOnNavigation) setOnNavigation(false);
    }, 400);
  };

  return (
    <S.BgWrapper style={slideAnimation}>
      <S.IconBox>
        <S.LogoImg src="/svg/commons/logo.svg" alt="logo" />
        <img
          src="/svg/commons/close.svg"
          alt="close"
          onClick={onClickCloseButton}
        />
      </S.IconBox>
      {MenuItems.map(({ id, title, page }) => (
        <Link
          key={id}
          to={page}
          onClick={onClickCloseButton}
          style={MenuStyle(page)}
          smooth
        >
          {title}
        </Link>
      ))}
    </S.BgWrapper>
  );
};

export default Navigation;

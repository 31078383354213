import * as S from './styles';

const RoadmapContainer = () => {
  return (
    <S.BgWrapper>
      <S.Wrapper>
        <S.SubTitle>Our goal</S.SubTitle>
        <S.Title>Roadmap</S.Title>
        <S.MapBox order={1}>
          <S.MapTitle>3Q. 2023</S.MapTitle>
          <S.ContentsBox order={1}>
            <S.CheckImg src="/svg/roadmap/check_16.svg" alt="check" />
            <S.Description>Launching of FLUX</S.Description>
          </S.ContentsBox>
          <S.ContentsBox>
            <S.CheckImg src="/svg/roadmap/check_16.svg" alt="check" />
            <S.Description>The Opening of FLUX Official Site</S.Description>
          </S.ContentsBox>
        </S.MapBox>
        <S.MapBox order={2}>
          <S.MapTitle>4Q. 2023</S.MapTitle>
          <S.ContentsBox order={2}>
            <S.CheckImg src="/svg/roadmap/check_16.svg" alt="check" />
            <S.Description>Launching of FLUX Web Version</S.Description>
          </S.ContentsBox>
        </S.MapBox>
        <S.MapBox order={3}>
          <S.MapTitle>1Q. 2024</S.MapTitle>
          <S.ContentsBox order={3}>
            <S.CheckImg src="/svg/roadmap/check_16.svg" alt="check" />
            <S.Description type="web">
              Modularization of Liquidity Delegation
              <br />
              Solution Model
            </S.Description>
            <S.Description type="mob">
              Modularization of Liquidity
              <br />
              Delegation Solution Model
            </S.Description>
          </S.ContentsBox>
          <S.ContentsBox>
            <S.CheckImg src="/svg/roadmap/check_16.svg" alt="check" />
            <S.Description type="web">
              The Opening of Advanced
              <br />
              Liquidity Delegation Solution (FLUX 2.0)
            </S.Description>
            <S.Description type="mob">
              The Opening of Advanced Liquidity
              <br />
              Delegation Solution (FLUX 2.0)
            </S.Description>
          </S.ContentsBox>
        </S.MapBox>
        <S.MapBox order={4}>
          <S.MapTitle>2Q. 2024</S.MapTitle>
          <S.ContentsBox order={4}>
            <S.CheckImg src="/svg/roadmap/check_16.svg" alt="check" />
            <S.Description>
              Launching of Liquidity Delegation
              <br />
              Solution Model on other Mainnet
            </S.Description>
          </S.ContentsBox>
        </S.MapBox>
      </S.Wrapper>
    </S.BgWrapper>
  );
};

export default RoadmapContainer;

import styled from 'styled-components';
import { device } from '../../styles/media';

export const BgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 1420px;
  background-image: url('/png/aboutUs/aboutBG.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10px;

  @media ${device.wideTab} {
    height: 640px;
    background-image: url('/png/aboutUs/aboutBG_wideTab.png');
  }

  @media ${device.tab} {
    height: 640px;
    background-image: url('/png/aboutUs/aboutBG_tab.png');
  }

  @media ${device.mobile} {
    height: 720px;
    background-image: url('/png/aboutUs/aboutBG_mobile.png');
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1400px;
  width: 100%;
  height: 100%;
  padding: 80px 0;

  @media ${device.wideTab}, ${device.tab}, ${device.mobile} {
    padding: 60px 0;
  }
`;

export const SubTitle = styled.div`
  opacity: 50%;
  font-size: 1.5rem;

  @media ${device.wideTab}, ${device.tab} {
    font-size: 0.875rem;
  }

  @media ${device.mobile} {
    font-size: 1rem;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 3rem;

  @media ${device.wideTab}, ${device.tab} {
    font-size: 1.875rem;
  }

  @media ${device.mobile} {
    font-size: 1.75rem;
  }
`;

export const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${device.wideTab}, ${device.tab}, ${device.mobile} {
    display: none;
  }
`;

export const Section = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const TextBox = styled.div<{ type?: `PR` | `SE` | `ST` }>`
  flex-direction: column;
  padding-left: ${({ type = `PR` }) => {
    switch (type) {
      case `SE`:
        return `3%`;
      case `ST`:
        return `14%`;
      default:
        return `0%`;
    }
  }};
`;

export const ImageBox = styled.div<{ type?: `PR` | `SE` | `ST` }>`
  width: 100%;

  padding-left: ${({ type = `PR` }) => {
    switch (type) {
      case `SE`:
        return `3%`;
      case `ST`:
        return `0%`;
      default:
        return `23%`;
    }
  }};

  padding-top: ${({ type = `PR` }) => {
    switch (type) {
      case `SE`:
        return `115%`;
      case `ST`:
        return `38%`;
      default:
        return `20%`;
    }
  }};
`;

export const Image = styled.img`
  width: 340px;
`;

export const Contents = styled.div`
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 20px;
`;

export const SubContents = styled.div`
  font-size: 1.2rem;
`;

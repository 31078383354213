import * as S from './styles';

const Footer = () => {
  return (
    <S.Wrapper>
      <S.Text>ⓒ 2023 FLUX Foundation All rights reserved.</S.Text>
    </S.Wrapper>
  );
};

export default Footer;

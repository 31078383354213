import styled from 'styled-components';
import { device } from '../../../styles/media';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: end;

  @media ${device.web} {
    display: none;
  }

  @media ${device.mobile} {
    display: none;
  }
`;

export const TabBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  gap: 30px;

  @media ${device.mobile} {
    display: none;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.mobile} {
    width: 270px;
    height: 456px;
    background-color: #0e222b;
  }
`;

export const ImageBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

export const Image = styled.img`
  @media ${device.wideTab}, ${device.tab} {
    width: 152px;
  }

  @media ${device.mobile} {
    width: 130px;
  }
`;

export const Contents = styled.div`
  @media ${device.wideTab}, ${device.tab} {
    font-size: 1rem;
    display: block;
    font-weight: 600;
    margin-bottom: 20px;
  }
`;

export const SubContentsTab = styled.div`
  display: block;
  font-size: 0.9rem;

  @media ${device.mobile} {
    display: none;
  }
`;

import styled from 'styled-components';
import { device } from '../../styles/media';

export const BgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0 200px 0;
  width: 100vw;
  height: 1912px;
  background-color: #000000;
  background-image: url('/png/business/businessBG.png');
  background-position: bottom;
  background-repeat: no-repeat;

  @media ${device.wideTab} {
    height: 993px;
    padding: 80px 0;
    background-position: left bottom;
    background-image: url('/png/business/businessBG_wideTab.png');
  }

  @media ${device.tab} {
    height: 993px;
    padding: 80px 0;
    background-position: left bottom;
    background-image: url('/png/business/businessBG_tab.png');
  }

  @media ${device.mobile} {
    height: 1520px;
    padding: 80px 0;
    background-position: left bottom;
    background-image: url('/png/business/businessBG_mobile.png');
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 0 9%;

  @media ${device.wideTab} {
    padding: 0 13%;
    flex-direction: column;
  }

  @media ${device.tab} {
    padding: 0 6%;
    flex-direction: column;
  }

  @media ${device.mobile} {
    padding: 0 20px;
    flex-direction: column;
  }
`;

export const TitleBox = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;

  @media ${device.mobile} {
    width: 100%;
    text-align: center;
  }
`;

export const SubTitle = styled.div`
  font-weight: 400;
  font-size: 1.5rem;
  opacity: 50%;

  @media ${device.wideTab}, ${device.tab} {
    font-size: 0.875rem;
  }

  @media ${device.mobile} {
    font-size: 1rem;
    margin-bottom: 5px;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 3rem;
  margin-bottom: 80px;

  @media ${device.wideTab}, ${device.tab} {
    font-size: 1.875rem;
    margin-bottom: 0;
  }

  @media ${device.mobile} {
    font-size: 1.75rem;
    margin-bottom: 0;
  }
`;

export const ContentBox = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  @media ${device.wideTab}, ${device.tab} {
    position: static;
    width: 100%;
  }

  @media ${device.mobile} {
    position: static;
    width: 100%;
    margin-top: 0;
  }
`;

export const ContentTitle = styled.div<{ order?: 1 | 2 }>`
  font-size: 3.75rem;
  font-weight: 600;
  color: #13f1ff;
  margin: 200px 0 4px 0;

  @media ${device.wideTab} {
    font-size: 1.125rem;
    padding-left: 90px;
    margin: ${({ order }) => (order === 1 ? `45px 0 4px 0` : `104px 0 4px 0`)};
  }

  @media ${device.tab} {
    font-size: 1.125rem;
    padding-left: 140px;
    margin: ${({ order }) => (order === 1 ? `80px 0 4px 0` : `120px 0 4px 0`)};
  }

  @media ${device.mobile} {
    font-size: 1.25rem;
    padding-left: 0;
    margin: ${({ order }) => (order === 1 ? `80px 0 4px 0` : `40px 0 4px 0`)};
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${device.mobile} {
    flex-direction: column;
    justify-content: start;
  }
`;

export const DescriptionBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Description = styled.div<{ type?: `web` | `tab` | `mobile` }>`
  font-size: 1.125rem;
  display: ${({ type }) => (type === `web` ? `block` : `none`)};

  @media ${device.wideTab} {
    font-size: 0.875rem;
    padding-left: 90px;
    display: ${({ type }) => (type === `tab` ? `block` : `none`)};
  }

  @media ${device.tab} {
    font-size: 0.875rem;
    padding-left: 140px;
    display: ${({ type }) => (type === `tab` ? `block` : `none`)};
  }

  @media ${device.mobile} {
    font-size: 1rem;
    display: ${({ type }) => (type === `mobile` ? `block` : `none`)};
  }
`;

export const ImageBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media ${device.web}, ${device.wideTab}, ${device.tab} {
    display: none;
  }
`;

export const Image = styled.img<{ order?: 1 | 2 }>`
  width: 560px;
  position: absolute;
  right: 0;
  top: ${({ order }) => (order === 1 ? `20%` : `45%`)};

  @media (min-width: 1025px) and (max-width: 1350px) {
    width: 280px;
    top: ${({ order }) => (order === 1 ? `40%` : `50%`)};
  }

  @media ${device.wideTab}, ${device.tab} {
    width: 230px;
    top: ${({ order }) => (order === 1 ? `30%` : `35%`)};
  }

  @media ${device.mobile} {
    position: static;
    width: 280px;
    margin-top: 40px;
    display: ${({ order }) => (order ? `none` : `block`)};
  }
`;

import { Fade } from 'react-awesome-reveal';
import * as S from './styles';
import Footer from '../commons/layout/footer';

const BusinessContainer = () => {
  return (
    <S.BgWrapper>
      <S.Wrapper>
        <S.TitleBox>
          <S.SubTitle>Our strategy</S.SubTitle>
          <S.Title>Business</S.Title>
        </S.TitleBox>
        <S.ContentBox>
          <Fade duration={2000} direction="up">
            <>
              <S.ContentTitle order={1}>What we do</S.ContentTitle>
              <S.Description type="web">
                Flux Korea wants to help anyone adapt to the digital environment
                <br />
                and demonstrate their abilities in the era of digital
                transformation.
              </S.Description>
              <S.Description type="tab">
                Flux Korea wants to help anyone adapt to the digital environment
                <br />
                and demonstrate their abilities in the era of digital
                transformation.
              </S.Description>
              <S.Description type="mobile">
                Flux Korea wants to help anyone adapt
                <br />
                to the digital environment
                <br />
                and demonstrate their abilities in the
                <br />
                era of digital transformation.
              </S.Description>
            </>
          </Fade>

          <Fade duration={2000} direction="up">
            <S.Section>
              <S.ImageBox>
                <S.Image src="/svg/business/mission.svg" alt="vision" />
              </S.ImageBox>
              <S.DescriptionBox>
                <S.ContentTitle>Mission</S.ContentTitle>
                <S.Description type="web">
                  We will be a partner that allows anyone to
                  <br />
                  realize their dreams without limiting
                  <br />
                  possibilities.
                </S.Description>
                <S.Description type="tab">
                  We will be a partner that allows anyone to realize their
                  <br />
                  dreams without limiting possibilities.
                </S.Description>
                <S.Description type="mobile">
                  We will be a partner that allows anyone
                  <br />
                  to realize their dreams without limiting
                  <br />
                  possibilities.
                </S.Description>
              </S.DescriptionBox>
              <S.Image src="/svg/business/mission.svg" alt="vision" order={1} />
            </S.Section>
          </Fade>

          <Fade duration={2000} direction="up">
            <S.Section>
              <S.ImageBox>
                <S.Image src="/svg/business/vision.svg" alt="vision" />
              </S.ImageBox>
              <S.DescriptionBox>
                <S.ContentTitle>Vision</S.ContentTitle>
                <S.Description type="web">
                  We solve problems and build sustainable
                  <br />
                  development through IT technology.
                  <br />
                  Building the IT community that can solve
                  <br />
                  problems together.
                  <br />
                  We build an educational platform where
                  <br />
                  anyone can develop.
                </S.Description>
                <S.Description type="tab">
                  We solve problems and build sustainable
                  <br />
                  development through IT technology.
                  <br />
                  Building the IT community that can solve problems
                  <br />
                  together.
                  <br />
                  We build an educational platform where anyone can
                  <br />
                  develop.
                </S.Description>
                <S.Description type="mobile">
                  We solve problems and build
                  <br />
                  sustainable development through IT
                  <br />
                  technology.
                  <br />
                  Building the IT community that can
                  <br />
                  solve problems together.
                  <br />
                  We build an educational platform
                  <br />
                  where anyone can develop.
                </S.Description>
              </S.DescriptionBox>
              <S.Image src="/svg/business/vision.svg" alt="vision" order={2} />
            </S.Section>
          </Fade>
        </S.ContentBox>
      </S.Wrapper>
      <Footer />
    </S.BgWrapper>
  );
};

export default BusinessContainer;

import styled from 'styled-components';
import { device } from '../../styles/media';

export const BackWrapper = styled.div`
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 1080px;
  position: relative;

  @media ${device.wideTab} {
    height: 660px;
  }

  @media ${device.tab} {
    height: 620px;
  }

  @media ${device.mobile} {
    height: 580px;
  }
`;

export const BgWrapper = styled.div`
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 0;
`;

export const WebVideo = styled.video`
  width: 100%;
  height: 100%;
  pointer-events: none;

  @media ${device.mobile}, ${device.wideTab}, ${device.tab} {
    display: none;
  }
`;

export const TabVideo = styled.video`
  width: 100%;
  height: 100%;
  pointer-events: none;

  @media ${device.web}, ${device.mobile} {
    display: none;
  }
`;

export const MobileVideo = styled.video`
  width: 100%;
  pointer-events: none;

  @media ${device.web}, ${device.wideTab}, ${device.tab} {
    display: none;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 0 8%;
  z-index: 10;

  @media ${device.wideTab} {
    padding: 0 12%;
  }

  @media ${device.tab} {
    padding: 0 5%;
    margin-top: 50px;
  }

  @media ${device.mobile} {
    padding: 0 20px;
    margin-top: 30px;
  }
`;

export const Title = styled.div<{ type: `web` | `mob` }>`
  font-family: Helvetica;
  font-weight: 700;
  font-size: 5rem;
  display: ${({ type }) => (type === `web` ? `block` : `none`)};

  @media ${device.wideTab} {
    font-size: 2.7rem;
  }

  @media ${device.tab} {
    font-size: 2.625rem;
  }

  @media ${device.mobile} {
    font-size: 2.125rem;
    display: ${({ type }) => (type === `mob` ? `block` : `none`)};
  }
`;

export const ContentsBox = styled.div`
  margin-top: 20px;
`;

export const Contents = styled.div<{ type: `web` | `mob` }>`
  font-weight: 400;
  font-size: 1.4rem;
  display: ${({ type }) => (type === `web` ? `block` : `none`)};

  @media ${device.wideTab}, ${device.tab} {
    font-size: 1rem;
  }

  @media ${device.mobile} {
    font-size: 1rem;
    display: ${({ type }) => (type === `mob` ? `block` : `none`)};
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 40px;

  @media ${device.mobile} {
    width: 100%;
    margin-top: 30px;
    gap: 15px;
  }
`;

export const ButtonLink = styled.a`
  text-decoration: none;
  color: #ffffff;
`;

export const Button = styled.div<{ type: `LG` | `BD` }>`
  width: 200px;
  height: 56px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  background: ${({ type }) =>
    type === `BD` ? `none` : `linear-gradient(#15EDCC, #23A2B1)`};
  border: ${({ type }) => (type === `BD` ? `1.5px solid #ffffff` : `none`)};

  @media ${device.mobile} {
    width: 154px;
    height: 52px;
    font-weight: ${({ type }) => (type === `BD` ? `500` : `600`)};
    background: ${({ type }) =>
      type === `BD` ? `none` : `linear-gradient(#42E6F8, #23A2B1)\``};
  }
`;

import styled from 'styled-components';
import { device } from '../../styles/media';

export const BgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0;
  width: 100vw;
  height: 1200px;
  background-image: url('/png/roadmap/roadmapBG.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media ${device.wideTab} {
    background-image: url('/png/roadmap/roadmapBG_wideTab.png');
    height: 768px;
  }

  @media ${device.tab} {
    background-image: url('/png/roadmap/roadmapBG_tab.png');
    height: 768px;
  }

  @media ${device.mobile} {
    background-image: url('/png/roadmap/roadmapBG_mobile.png');
    height: 768px;
    padding: 80px 20px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  background-image: url('/svg/roadmap/roadmap.svg');
  background-repeat: no-repeat;
  background-position: center;

  @media (min-width: 1025px) and (max-width: 1250px) {
    background-image: url('/svg/roadmap/roadmap_wideTab.svg');
    background-position: 50% 48%;
  }

  @media ${device.wideTab}, ${device.tab} {
    background-image: url('/svg/roadmap/roadmap_tab.svg');
    background-position: 50% 100%;
  }

  @media ${device.mobile} {
    background-image: url('/svg/roadmap/roadmap_mobile.svg');
    background-position: 5% 77px;
    position: static;
    align-items: start;
  }
`;

export const SubTitle = styled.div`
  font-weight: 400;
  font-size: 1.5rem;

  @media ${device.wideTab}, ${device.tab} {
    font-size: 0.875rem;
  }

  @media ${device.mobile} {
    width: 100%;
    display: flex;
    justify-content: center;
    opacity: 50%;
    font-size: 1rem;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 3rem;
  margin-bottom: 80px;

  @media ${device.wideTab}, ${device.tab} {
    font-size: 1.875rem;
    margin-bottom: 60px;
  }

  @media ${device.mobile} {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 1.75rem;
    margin-bottom: 60px;
  }
`;

export const MapBox = styled.div<{ order: 1 | 2 | 3 | 4 }>`
  width: 368px;
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: ${({ order = 1 }) => (order % 2 === 0 ? `right` : `left`)};
  left: ${({ order = 1 }) => (order % 2 === 0 ? `-410px` : `420px`)};

  @media (min-width: 1025px) and (max-width: 1250px) {
    left: ${({ order = 1 }) => (order % 2 === 0 ? `-300px` : `300px`)};
  }

  top: ${({ order = 1 }) => {
    switch (order) {
      case 2:
        return `8%`;
      case 3:
        return `11.5%`;
      case 4:
        return `7%`;
      default:
        return `7%`;
    }
  }};

  @media ${device.wideTab}, ${device.tab} {
    width: 260px;

    left: ${({ order }) => (order % 2 === 0 ? `-240px` : `240px`)};

    top: ${({ order = 1 }) => {
      switch (order) {
        case 2:
          return `9.5%`;
        case 3:
          return `14.5%`;
        case 4:
          return `6%`;
        default:
          return `8%`;
      }
    }};
  }

  @media ${device.mobile} {
    width: 100%;
    position: static;
    text-align: left;

    margin-bottom: ${({ order = 1 }) => {
      switch (order) {
        case 2:
          return `35px`;
        case 3:
          return `35.5px`;
        case 4:
          return `0`;
        default:
          return `36.5px`;
      }
    }};
  }
`;

export const MapTitle = styled.div`
  width: 100%;
  font-family: Inter;
  font-size: 2rem;
  font-weight: 600;
  color: #eefdfd;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  @media ${device.wideTab}, ${device.tab} {
    font-size: 1rem;
  }

  @media ${device.mobile} {
    margin-bottom: 10px;
    padding-bottom: 0;
    padding-left: 65px;
    border-bottom: none;
    font-size: 1rem;
  }
`;

export const CheckImg = styled.img`
  width: 22px;
  height: 27px;
  padding: 5px 5.5px 0 0;

  @media ${device.wideTab}, ${device.tab} {
    width: 20px;
    height: 16px;
    padding: 2px 2px 0 0;
  }

  @media ${device.mobile} {
    width: 16px;
    height: 16px;
    padding: 3px 5px 0 0;
  }
`;

export const ContentsBox = styled.div<{ order?: 1 | 2 | 3 | 4 }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  justify-content: ${({ order = 1 }) => (order % 2 === 0 ? `right` : `left`)};

  @media ${device.wideTab}, ${device.tab}, ${device.mobile} {
    justify-content: left;
    margin-bottom: 5px;
  }

  @media ${device.mobile} {
    margin-bottom: 5px;
    padding-left: 65px;
  }
`;

export const Description = styled.div<{ type?: `web` | `mob` }>`
  font-size: 1.125rem;
  color: #eefdfd;
  display: ${({ type }) => (type === `mob` ? `none` : `block`)};

  @media ${device.wideTab}, ${device.tab}, ${device.mobile} {
    font-size: 0.875rem;
    display: ${({ type }) => (type === `web` ? `none` : `block`)};
  }
`;

import styled from 'styled-components';
import { device } from '../../../../styles/media';

export const ButtonBox = styled.a`
  position: fixed;
  right: 40px;
  bottom: 105px;
  cursor: pointer;

  @media ${device.mobile} {
    right: 20px;
    bottom: 85px;
  }
`;

export const MenuItems = [
  { id: 1, title: `Home`, page: `/` },
  { id: 2, title: `About Us`, page: `/aboutus` },
  { id: 3, title: `Service`, page: `/service` },
  { id: 4, title: `Roadmap`, page: `/roadmap` },
  { id: 5, title: `Business`, page: `/business` },
];

export const goToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: `smooth` });
};

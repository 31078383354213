import * as S from './styles';

const fluxEduButton = () => {
  return (
    <S.ButtonBox href="https://edu.k-flux.io/" target="_blank" rel="noreferrer">
      <img src="/svg/commons/fluxEdu.svg" alt="fluxEduButton" />
    </S.ButtonBox>
  );
};

export default fluxEduButton;

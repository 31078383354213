import styled from 'styled-components';
import { device } from '../../../../styles/media';

export const Wrapper = styled.div<{ $blurHeader?: boolean }>`
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 60px;
  position: fixed;
  z-index: 20;
  -webkit-backdrop-filter: ${({ $blurHeader }) =>
    $blurHeader ? `blur(10px)` : `none`};
  backdrop-filter: ${({ $blurHeader }) =>
    $blurHeader ? `blur(10px)` : `none`};

  @media ${device.mobile} {
    height: 56px;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 9%;

  @media ${device.wideTab} {
    padding: 0 12%;
  }

  @media ${device.tab} {
    padding: 0 5%;
  }

  @media ${device.mobile} {
    padding: 0 20px;
  }
`;

export const logoBox = styled.div`
  display: flex;
`;

export const LogoImg = styled.img`
  width: 42px;
  cursor: pointer;
`;

export const MenuBox = styled.div`
  width: 740px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media ${device.wideTab}, ${device.tab} {
    width: 564px;
  }

  @media ${device.mobile} {
    display: none;
  }
`;

export const Hamburger = styled.img`
  @media ${device.web}, ${device.wideTab}, ${device.tab} {
    display: none;
  }

  @media ${device.mobile} {
    display: block;
  }
`;

import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import * as S from './styles';

const AboutUsCarousel = () => {
  const settings = {
    centerMode: true,
    draggable: true,
    arrows: false,
    dots: true,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: `0px`,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
  };

  return (
    <S.Wrapper>
      <S.Slide {...settings}>
        <S.Section>
          <S.ImageBox>
            <S.Image src="/svg/aboutUs/property.svg" alt="property" />
          </S.ImageBox>
          <div>
            <S.Contents>Core liquidity services</S.Contents>
            <S.Description>
              FLUX, is the core liquidity
              <br />
              provider to passionate about
              <br />
              bringing your valuable
              <br />
              cryptocurrency and delegated
              <br />
              assets to achieve brighter future.
            </S.Description>
          </div>
        </S.Section>
        <S.Section>
          <S.ImageBox>
            <S.Image src="/svg/aboutUs/security.svg" alt="security" />
          </S.ImageBox>
          <div>
            <S.Contents>Liquidity providers</S.Contents>
            <S.Description>
              FLUX, is to create and operate
              <br />
              the leading liquidity protocol on
              <br />
              the various ways that we offer
              <br />
              access to new utilizing assets to
              <br />
              our delegated users based on
              <br />
              the Ground Chain (GND).
            </S.Description>
          </div>
        </S.Section>
        <S.Section>
          <S.ImageBox>
            <S.Image src="/svg/aboutUs/staking.svg" alt="staking" />
          </S.ImageBox>
          <div>
            <S.Contents>Steady Liquidity protocol</S.Contents>
            <S.Description>
              FLUX, is a based liquidity
              <br />
              protocol that has been created
              <br />
              by fundamental of stETH from
              <br />
              the Ethereum 2.0 liquidity
              <br />
              staking solution to ensure a
              <br />
              steady supply of liquidity.
            </S.Description>
          </div>
        </S.Section>
      </S.Slide>
    </S.Wrapper>
  );
};

export default AboutUsCarousel;
